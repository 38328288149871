<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        title="Reseller Order List"
        icon="mdi-briefcase"
        sort="orderId"
        :descending="true"
        :show-add-button="false"
        stateendpoint="salesCounter.salesCounters"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButton"
        :show-sale-counter-button="true"
    />
  </div>
</template>
<script>
import ListPage from "../../components/ListPage";

export default {
  name: 'Invoices',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Reseller Order List | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage},
  data() {
    return {
      headers: [
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Billing Type',
          value: 'billingType'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Total Amount',
          value: 'totalAmount'
        },
        {
          text: 'Status',
          value: 'paymentStatus'
        },
        {
          text: 'Is Prepaid?',
          value: 'isPrepayment'
        },
        {
          text: 'Order From',
          value: 'contactMail'
        },
        {
          text: 'Order Date',
          value: 'orderDate'
        },
        {
          text: 'Updated Date',
          value: 'updatedAt'
        },
        {
          text: 'Paid Date',
          value: 'paymentDate'
        },
        {
          text: 'User',
          value: 'user'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'name'},
        {value: 'isActive'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadSalesCounters',
        update: 'storeSalesCounter',
        postRemove: 'postRemoveSalesCounter',
        remove: 'removeSalesCounter',
        destroy: 'destroySalesCounter',
        restore: 'restoreSalesCounter',
        republish: 'republishSalesCounter',
        deleteWithoutPost: 'destroySalesCounterWithoutPost',
      },
      actionButton: [
        {
          category: 'edit',
          icon: 'mdi-pencil',
          color: '',
          text: 'Edit',
          clickHandler: 'editSalesCounterHandler'
        },
        {
          category: 'remove',
          icon: 'mdi-basket-remove',
          color: 'error',
          text: 'Remove',
          clickHandler: 'removePostItem'
        },
        {
          category: 'delete',
          icon: 'mdi-delete',
          color: 'error',
          text: 'Delete',
          clickHandler: 'deleteItem'
        },
        {
          category: 'deletePermanent',
          icon: 'mdi-delete-forever',
          color: 'error',
          text: 'Delete Forever',
          clickHandler: 'destroyItem'
        },
        {
          category: 'deleteWithoutPost',
          icon: 'mdi-delete-forever',
          color: 'error',
          text: 'Delete without Post',
          clickHandler: 'deleteWithoutItem'
        },
        {
          category: 'restore',
          icon: 'mdi-backup-restore',
          color: '',
          text: 'Restore',
          clickHandler: 'restoreHandler'
        },
        {
          category: 'link',
          icon: 'mdi-eye',
          color: '',
          text: 'View Live Link',
          clickHandler: 'counterLinkHandler'
        },
        {
          category: 'details',
          icon: 'mdi-details',
          color: '',
          text: 'View Details',
          clickHandler: 'salesDetailsHandler'
        },
        {
          category: 'republish',
          icon: 'mdi-publish',
          color: '',
          text: 'Article Republish',
          clickHandler: 'republishHandler'
        },
        {
          category: 'replacement',
          icon: 'mdi-file-replace-outline',
          color: '',
          text: 'Live Link Replacement',
          clickHandler: 'liveLinkReplacementHandler'
        },
        {
          category: 'prepaid',
          icon: 'mdi-stack-exchange',
          color: '',
          text: 'Change Prepayment Status',
          clickHandler: 'onChangedPrepaymentStatus'
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('loadAllAdminUsers');
  }
}
</script>
